<template>
    <b-container>
        <b-row class="mb-5" align-v="center">
            <b-col cols="12" lg="6">
                <h1 class="mb-5 text-center">Thalasso Bain Bébé©</h1>
                <p>
                    Le Thalasso Thérapeutique Bain Bébé© a été créé par Sonia Krief. Aussi
                    appelé Le Bain de Sonia. Plus qu'un bain, c'est un soin,
                    un lien. C'est un cadeau de bienvenue. Un bain d'émotions.
                    Il permet au bébé de revivre ses sensations intra utérines,
                    des postures qu'il avait dans le giron maternel.<br />
                    <br />
                    Le maître mot est la confiance : les parents nous font
                    confiance, le bébé nous fait confiance et nous avons confiance
                    en lui. En sa capacité à nous guider.<br />
                    <br />
                </p>
            </b-col>
            <b-col cols="12" lg="6" offset-lg="0">
                <non-clickable-img url="TBB1.jpg" />
            </b-col>
        </b-row>
        <b-row class="mb-5" align-v="center">
            <b-col cols="12" class="d-lg-none">
                <p>

                    Lors de ce soin, nous proposons, nous n'imposons rien. Le nouveau
                    né est libre de nous diriger vers ce qui lui semble lui convenir
                    le plus. En gesticulant, nous lui offrons un espace de liberté
                    et d'expressions, qui lui permettront de se libérer d'émotions
                    plus ou moins difficiles liées à sa vie intra utérine ou à sa
                    naissance.<br />
                    <br />
                    C'est un bain de patience. Le temps s'arrête. La magie opère.
                    On écoute, on observe, on s'émerveille. Le bien être est
                    communicatif, les parents se sentent bien, ils sont émus.<br />
                    <br />
                    C'est très difficile pour moi de vous décrire le Thalasso
                    Bain Bébé tellement il change d'un bébé à l'autre. Il est
                    indescriptible.<br />
                    1 bébé = 1 TBB. Chaque bain est unique...<br />
                    <br />

                </p>
            </b-col>
            <b-col cols="12" lg="6" offset-lg="0">
                <non-clickable-img url="TBB2.png" />
            </b-col>
            <b-col cols="6" class="d-none d-lg-inline">
                <p>

                    Lors de ce soin, nous proposons, nous n'imposons rien. Le nouveau
                    né est libre de nous diriger vers ce qui lui semble lui convenir
                    le plus. En gesticulant, nous lui offrons un espace de liberté
                    et d'expressions, qui lui permettront de se libérer d'émotions
                    plus ou moins difficiles liées à sa vie intra utérine ou à sa
                    naissance.<br />
                    <br />
                    C'est un bain de patience. Le temps s'arrête. La magie opère.
                    On écoute, on observe, on s'émerveille. Le bien être est
                    communicatif, les parents se sentent bien, ils sont émus.<br />
                    <br />
                    C'est très difficile pour moi de vous décrire le Thalasso
                    Bain Bébé tellement il change d'un bébé à l'autre. Il est
                    indescriptible.<br />
                    1 bébé = 1 TBB. Chaque bain est unique...<br />
                    <br />

                </p>
            </b-col>
        </b-row>
        <b-row class="mb-5" align-v="center">
            <b-col cols="12" lg="6">
                <p>
                    Le Thalasso Thérapeutique Bain Bébé© est véritablement bénéfique pour les
                    parcours et les naissances difficiles (séparation à la naissance par exemple), les césariennes, les
                    prématurés, mais aussi pour les bébés nés à terme sans
                    complications. Le TBB est un soin réparateur et vous apportera de la douceur dans ces premiers jours parfois délicats.<br />
                    <br />
                    Ce soin comprend un temps d’échange autour de votre histoire
                    et celle de votre bébé. Ensuite nous nous installerons pour le bain, puis, viendra le temps d'une tétée
                    ou profiter d'un moment en peau à peau...nous nous laisserons guider par votre
                    bébé et ses besoins. Les bienfaits du lait maternel
                    sont nombreux, si vous le souhaitez, vous pouvez en conserver pour le
                    mettre dans la baignoire le jour du bain.<br />
                    <br />
                    Le Thalasso Thérapeutique Bain Bébé© se réalise le <b>premier mois</b> de vie
                    de votre bébé (au-delà si prématurité). Il est préférable
                    de me contacter pendant la <b>grossesse</b> pour prendre rendez-vous.<br />
                    <br />
                    Deux possibilités s'offrent à vous, je peux vous accueillir :<br />
                    - à votre domicile*.<br />
                    - à mon cabinet à Auzeville-Tolosane, <b-link 
                        href="https://www.google.fr/maps/place/Le+Cocon,+maison+de+la+P%C3%A9rinatalit%C3%A9+et+de+la+sant%C3%A9+des+femmes/@43.5349388,1.4806887,17z/data=!3m1!4b1!4m6!3m5!1s0x12aebe8d9050104d:0xfe6f2bed61b6bd06!8m2!3d43.5349349!4d1.4832636!16s%2Fg%2F11c744kdq6?entry=ttu&g_ep=EgoyMDI0MTEyNC4xIKXMDSoASAFQAw%3D%3D"
                        target="_blank">
                            au Cocon
                    </b-link><br />
                    <br />
                    Parce qu'il est important pour moi de vous accompagner selon vos besoins. N'hésitez pas à me contacter pour en discuter et trouver ce qui vous correspond le mieux. <br />
                    <price :price="100" :price_in_addition="false"/>
                </p>
            </b-col>
            <b-col cols="12" lg="6" offset-lg="0">
                <non-clickable-img url="TBB3.jpg" />
            </b-col>
        </b-row>
        <b-row class="mb-5" align-v="center">
            <b-col cols="12">
                <p class="text-center">
                    En collaboration avec Mathilde, photographe professionnelle, nous avons créé
                    un <b-link to="/packs"><b>pack souvenir</b></b-link> pour immortaliser ce joli
                    moment et vous laisser en profiter au maximum. Vous pouvez découvrir son travail juste après...
                </p>
            </b-col>
        </b-row>
        <b-row :class="[tbb4_tbb5_class]" align-v="center">
            <b-col cols="12" lg="6">
                <non-clickable-img url="TBB4.jpg" />
            </b-col>
            <b-col cols="12" lg="6">
                <non-clickable-img url="TBB5.jpg" />
            </b-col>
        </b-row>
        <b-row class="mb-3" align-v="center" align-h="center">
            <b-col cols="12" lg="6">
                <non-clickable-img url="TBB6.jpg" />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import Price from '../components/Price';
import NonClickableImg from '../components/NonClickableImg';

export default {
    name: 'WrappedBath',
    components: { Price, NonClickableImg },
    data: function () {
        return {
            large_breakpoint: 992,
            windowWidth: window.innerWidth,
            tbb4_tbb5_class: '',
        };
    },
    mounted() {
        window.onresize = () => {
            this.windowWidth = window.innerWidth;
            this.tbb4_tbb5_class = this.windowWidth >= this.large_breakpoint ? 'mb-5' : '';
        }; 

        this.tbb4_tbb5_class = this.windowWidth >= this.large_breakpoint ? 'mb-5' : '';
    },
};
</script>

<style scoped>
h1 {
    line-height: 150%;
}

p {
    text-align: justify;
    text-justify: inter-word;
}
</style>
