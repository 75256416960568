import { render, staticRenderFns } from "./BabyBathThalasso.vue?vue&type=template&id=a597618c&scoped=true&"
import script from "./BabyBathThalasso.vue?vue&type=script&lang=js&"
export * from "./BabyBathThalasso.vue?vue&type=script&lang=js&"
import style0 from "./BabyBathThalasso.vue?vue&type=style&index=0&id=a597618c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a597618c",
  null
  
)

export default component.exports